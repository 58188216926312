.calendarIcon {
  font-size: 18px;

  &:hover {
    cursor: pointer;
    color: #9c753d;
  }
}

.logoutButton:hover {
  color: #9c753d !important;
}
